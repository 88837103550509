import * as React from 'react';

import { getGridRows } from '../utils';
import { Layout } from '../components/layout';
import { colorValues, Grid, Header, StyledLink } from '.';
import { Rectangle } from '../components/rectangle';
import { useWindowSize } from '../hooks/useWindowSize';

const NotFoundPage = () => {
  const { width } = useWindowSize();
  const gridSize = width && width <= 475 ? `${width / 10}px` : '40px';
  const gridRows = getGridRows(colorValues, 10);
  return (
    <Layout title="404">
      <Header>404</Header>
      <StyledLink to="/">/</StyledLink>
      <Grid stops={10}>
        {gridRows.map(colors =>
          colors.map(c => (
            <Rectangle
              width={gridSize}
              height={gridSize}
              color={c.toRgbString()}
            />
          )),
        )}
      </Grid>
    </Layout>
  );
};

export default NotFoundPage;
